












































































































































































import { debounce } from 'throttle-debounce'
import { defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch } from '@vue/composition-api'
import { useActions, useGetters, useMutations } from '@u3u/vue-hooks'

import VCheckbox from '@/components/form/VCheckbox.vue'
import VInput from '@/components/form/VInput.vue'
import Alert from '@/components/ui/Alert.vue'
import Layout from '@/components/Layout.vue'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'

import callApi from '@/composables/fetchapi'
import { userProfile, zipcode } from '@/inc/endpoints'
import { NotificationSeverity } from '@/inc/types'

export default defineComponent({
  name: 'profile',
  components: { VCheckbox, Alert, Layout, VInput, FeedbackMessage },
  props: {},

  setup() {
    const { user: userData } = useGetters('user', ['user'])
    const { setProfileChanged } = useMutations('ui', ['setProfileChanged'])
    const { SET_USER } = useMutations('user', ['SET_USER'])
    const { FETCH_USER_PROFILE: fetchUserProfile } = useActions('user', ['FETCH_USER_PROFILE'])

    const isLoading = ref(false)
    const feedbackMessage = ref<{ type: NotificationSeverity; htmltext: string } | null>()
    const user = reactive({ ...userData.value })
    const form = ref<HTMLFormElement | null>()
    const formError = ref(false)

    const onSubmit = () => {
      feedbackMessage.value = null
      formError.value = !form.value?.checkValidity()
      form.value?.reportValidity()

      if (!formError.value) {
        isLoading.value = true
        setProfileChanged(false)

        callApi(userProfile, { method: 'put', data: user })
          .then(() => {
            SET_USER(user)
            feedbackMessage.value = {
              type: 'success',
              htmltext: 'common.formSuccess',
            }
          })
          .catch(() => {
            feedbackMessage.value = {
              type: 'error',
              htmltext: 'common.formError',
            }
          })
          .finally(() => {
            isLoading.value = false
          })
      }
    }

    const beforeunload = e => {
      e.preventDefault()
      e.returnValue = ''
    }

    const cityList = ref([])
    const $cityList = ref<HTMLElement>()
    const cityListVisible = ref(false)
    const onCityOpen = (e: MouseEvent) => {
      if ($cityList.value?.contains(e.target as Node)) {
        return
      }

      closeCityList()
    }
    const openCityList = () => {
      if (cityListVisible.value) {
        return
      }

      window.addEventListener('click', onCityOpen, { capture: true })
      cityListVisible.value = true
    }
    const closeCityList = () => {
      window.removeEventListener('click', onCityOpen)
      cityListVisible.value = false
    }
    const getCity = debounce(300, (zip: string) => {
      cityList.value = []
      callApi(`${zipcode}/${zip}`).then(res => {
        cityList.value = res.data
        openCityList()
      })
    })
    const setCity = (city: string) => {
      closeCityList()
      user.address.city = city.toUpperCase()
    }
    const onZipFocus = () => {
      if (!cityList.value.length && user.address.zip) {
        getCity(user.address.zip)

        return
      }

      openCityList()
    }

    watch(user, () => {
      window.addEventListener('beforeunload', beforeunload)
      setProfileChanged(true)
    })

    watch(
      () => user.address.zip,
      val => {
        getCity(val)
      }
    )

    onMounted(() => {
      fetchUserProfile()
    })

    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', beforeunload)
    })

    return {
      onZipFocus,
      closeCityList,
      openCityList,
      cityListVisible,
      setCity,
      $cityList,
      cityList,
      isLoading,
      formError,
      feedbackMessage,
      form,
      onSubmit,
      user,
    }
  },
})
